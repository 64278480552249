import React, { useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import "./styles/MediaPlayer.css";

// eslint-disable-next-line
export default function MediaPlayer({
  albumID,
  playingType,
  tracks,
  toMinutes,
  playing,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  currentTime,
  duration,
  image,
  title,
  album,
  shuffle,
  setShuffle,
  loop,
  setLoop,
  autoplay,
  setAutoplay,
  list,
  setList,
  volume,
  setVolume,
  setChangeTime,
  mouseDown,
  viewAlbum,
}) {
  const index = list.indexOf(currentPlaying);

  const [wave, setWave] = useState(null);
  const [wave2, setWave2] = useState(null);

  useEffect(() => {
    setWave(WaveSurfer.create({
      container: document.querySelectorAll(".waveformContainer")[0],
      waveColor: "#dddddd",
      progressColor: "transparent",
      normalize: true,
      height: "auto",
      barWidth: 2,
      barGap: 1,
      barRadius: 2,
    }));
    setWave2(WaveSurfer.create({
      container: document.querySelectorAll(".waveformContainer")[1],
      waveColor: "#dddddd",
      progressColor: "transparent",
      normalize: true,
      height: "auto",
      barWidth: 2,
      barGap: 1,
      barRadius: 2,
    }));
  }, []);

  useEffect(() => {
    if (list.length !== 0) {
      wave.load(
        tracks[tracks.findIndex((track) => track.id === currentPlaying)].filePath
      );
      wave2.load(
        tracks[tracks.findIndex((track) => track.id === currentPlaying)].filePath
      );
    }
    // eslint-disable-next-line
  }, [currentPlaying]);

  useEffect(() => {
    let volumePercent;

    function volumeInc() {
      if (volumePercent >= 95) return 100;
      else return volumePercent + 5;
    }

    function volumeDec() {
      if (volumePercent <= 5) return 0;
      else return volumePercent - 5;
    }

    const volumeEl = document.querySelector("#player .volume-outer");
    let end;
    let wait = false;

    document.querySelector("#player").onwheel = (e) => {
      volumePercent = Number(document.querySelector("#player .volume-container").dataset.volume);

      e.preventDefault();

      if (e.deltaY < 0) {
        setVolume(volumeInc());
        volumeEl.style.width = "174px";
      } else if (e.deltaY > 0) {
        setVolume(volumeDec());
        volumeEl.style.width = "174px";
      }

      end = Date.now() + 1500;

      if (!wait) {
        wait = true;
        stop();
      }
    };

    document.querySelector("#player").addEventListener("keydown", (e) => {
      volumePercent = Number(document.querySelector("#player .volume-container").dataset.volume);

      if (e.key === "ArrowDown" && document.querySelector("#player").style.bottom === "0px") {
        e.preventDefault();
        setVolume(volumeDec());
        volumeEl.style.width = "174px";
      } else if (e.key === "ArrowUp" && document.querySelector("#player").style.bottom === "0px") {
        e.preventDefault();
        setVolume(volumeInc());
        volumeEl.style.width = "174px";
      }

      end = Date.now() + 1500;

      if (!wait) {
        wait = true;
        stop();
      }
    });

    function stop() {
      if (end <= Date.now()) {
        volumeEl.removeAttribute("style");
        wait = false;
      } else {
        setTimeout(stop, 100);
      }
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.querySelector("#player .volume-container").dataset.volumeState =
      Math.floor(volume) === 0 ? "mute" :
      Math.floor(volume) < 50 ? "low" :
      "high";
  }, [volume]);

  function changeVolume() {
    const volumeContainer = document.querySelector("#player .volume-container");
    const volumeBar = document.querySelector("#player .volume-bar-outer");
    const mute = document.querySelector("#volume-icon");

    let mouseDown = false;
    let previousVolume, previousWidth, previousState;

    function updateVolume(e) {
      const volumeValue =
        (e.clientX - volumeBar.getBoundingClientRect().left)
        / volumeBar.clientWidth;

      const volumePercent =
        volumeValue <= 0 ? 0 :
        volumeValue >= 1 ? 100 :
        volumeValue * 100;

      setVolume(volumePercent);
    }

    volumeBar.addEventListener("mousedown", (e) => {
      mouseDown = true;
      updateVolume(e);
    });

    document.addEventListener("mousemove", (e) => {
      if (mouseDown) updateVolume(e);
    });

    document.addEventListener("mouseup", () => {
      mouseDown = false;
    });

    function muteAudio() {
      let currentVolume = volumeContainer.dataset.volume;
      let currentWidth = volumeContainer.getAttribute("style");
      let currentState = volumeContainer.dataset.volumeState;

      if (volumeContainer.dataset.volumeState === "mute") {
        setVolume(previousVolume);
        volumeContainer.style = previousWidth;
        volumeContainer.dataset.volumeState = previousState;
      } else {
        setVolume(0);
        volumeContainer.dataset.volumeState = "mute";
      }

      previousVolume = currentVolume;
      previousWidth = currentWidth;
      previousState = currentState;
    }

    let wait = false;
    mute.addEventListener("click", muteAudio);
    document.addEventListener("keyup", (e) => {
      if (e.target === document.querySelector("#player") && e.key === "m") {
        e.preventDefault();
        if (!wait) {
          wait = true;
          muteAudio();
          setTimeout(() => wait = false, 200);
        }
      }
    });
  }

  useEffect(() => {
    changeVolume();
  // eslint-disable-next-line
  }, []);

  const [hoverPosition, setHoverPosition] = useState(0);
  const [seekPosition, setSeekPosition] = useState("BACKWARD");

  function changeTime() {
    const progress = document.querySelector("#player > .progress-bar");
    let mouseDown;

    function updateTime(e) {
      const timeValue = (e.clientX / progress.clientWidth) * duration;
      setChangeTime(timeValue);
    }

    progress.addEventListener("mousedown", (e) => {
      mouseDown = true;
      setSeekPosition("SEEKING");
    });

    document.addEventListener("mousemove", (e) => {
      setHoverPosition(e.clientX / progress.clientWidth);
    });

    document.addEventListener("mouseup", (e) => {
      if (mouseDown) {
        setSeekPosition("BACKWARD");
        updateTime(e);
        mouseDown = false;
      }
    });
  }

  useEffect(() => {
    if (seekPosition !== "SEEKING") {
      if (hoverPosition < (currentTime / duration)) {
        setSeekPosition("BACKWARD");
      } else {
        setSeekPosition("FORWARD");
      }
    }
  }, [seekPosition, hoverPosition, currentTime, duration]);

  useEffect(() => {
    changeTime();
  // eslint-disable-next-line
  }, [duration]);

  function youtube() {
    console.log("MediaPlayer.jsx: YouTube button clicked.");
  }

  function featuredVideo() {
    console.log("MediaPlayer.jsx: Featured Player button clicked.");
  }

  function shuffleList() {
    setShuffle(!shuffle);
  }

  function previousTrack() {
    setCurrentPlaying(list[index - 1]);
  }

  function play_pause() {
    setPlaying(!playing);
  }

  function nextTrack() {
    setCurrentPlaying(list[index + 1]);
  }

  function loopTrack() {
    switch (loop) {
      case 0:
        setLoop(1);
        break;
      case 1:
        setLoop(2);
        break;
      case 2:
        setLoop(0);
        break;
      default:
        setLoop(0);
        break;
    }
  }

  function autoPlay() {
    setAutoplay(!autoplay);
  }

  function closePlayer() {
    document.body.click();
    document.querySelector("#player").style.bottom = "-68px";
    document.querySelector("#player > .progress-bar").style.pointerEvents = "none";
    setPlaying(false);
    setCurrentPlaying(null);
    setList([]);
  }

  useEffect(() => {
    if (playingType === "video") {
      closePlayer();
    }
  // eslint-disable-next-line
  }, [playingType]);

  const [shuffleWait, setShuffleWait] = useState(false);
  const [previousWait, setPreviousWait] = useState(false);
  const [nextWait, setNextWait] = useState(false);
  const [loopWait, setLoopWait] = useState(false);
  const [autoplayWait, setAutoplayWait] = useState(false);
  document.onkeyup = (e) => {
    if (list.length !== 0 && currentPlaying !== null && e.target === document.querySelector("#player")) {
      switch (e.key) {
        case "s":
          e.preventDefault();
          if (!shuffleWait) {
            setShuffleWait(true);
            shuffleList();
            setTimeout(() => setShuffleWait(false), 200);
          }
          break;
        case "p":
          e.preventDefault();
          if (!previousWait) {
            setPreviousWait(true);
            if (index !== 0) previousTrack();
            setTimeout(() => setPreviousWait(false), 200);
          }
          break;
        case "n":
          e.preventDefault();
          if (!nextWait) {
            setNextWait(true);
            if (index !== (list.length - 1)) nextTrack();
            setTimeout(() => setNextWait(false), 200);
          }
          break;
        case "l":
          e.preventDefault();
          if (!loopWait) {
            setLoopWait(true);
            loopTrack();
            setTimeout(() => setLoopWait(false), 200);
          }
          break;
        case "a":
          e.preventDefault();
          if (!autoplayWait) {
            setAutoplayWait(true);
            autoPlay();
            setTimeout(() => setAutoplayWait(false), 200);
          }
          break;
        case "Escape":
          e.preventDefault();
          closePlayer();
          break;
        default:
          break;
      }
    }
  }

  const rating = {
    positive: 127,
    negative: 21,
  }
  const [userRating, setUserRating] = useState("UNRATED");
  const [positiveRating, setPositiveRating] = useState(rating.positive);
  const [negativeRating, setNegativeRating] = useState(rating.negative);

  function thumbsUp() {
    if (userRating === "POSITIVE") {
      setUserRating("UNRATED");
      setPositiveRating(rating.positive);
      setNegativeRating(rating.negative);
    } else {
      setUserRating("POSITIVE");
      setPositiveRating(rating.positive + 1);
      setNegativeRating(rating.negative);
    }
  }

  function thumbsDown() {
    if (userRating === "NEGATIVE") {
      setUserRating("UNRATED");
      setPositiveRating(rating.positive);
      setNegativeRating(rating.negative);
    } else {
      setUserRating("NEGATIVE");
      setPositiveRating(rating.positive);
      setNegativeRating(rating.negative + 1);
    }
  }

  const [menuOpen, setMenuOpen] = useState(false);
  function menu() {
    if (menuOpen) {
      setMenuOpen(false);
      document.body.onmousedown = null;
    } else {
      setMenuOpen(true);
      document.body.onmousedown = () => {
        setMenuOpen(false);
      }
    }
  }

  const [optionsOpen, setOptionsOpen] = useState(false);
  function options() {
    if (optionsOpen) {
      setOptionsOpen(false);
      document.body.onmousedown = null;
    } else {
      setOptionsOpen(true);
      document.body.onmousedown = () => {
        setOptionsOpen(false);
      }
    }
  }

  return (
    <div id="player" data-open={currentPlaying !== null} tabindex="0">
      <div className="left">
        <button
          id="album-menu"
          className="control-button"
          title="Menu"
          onClick={menu}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <svg width="18" height="12" viewBox="0 0 18 12" fill="#dddddd">
            <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" />
          </svg>
        </button>
        <div
          className="vertical-divider"
          style={{
            marginLeft: "14px",
            marginRight: "0",
          }}
        ></div>
        <img
          className="album-thumbnail"
          src={image}
          alt={album}
          draggable="false"
        />
        <button className="playing-details">
          <span style={{ color: "#dddddd", marginBottom: "3px" }}>{title}</span>
          <a
            href={`/media?id=a${(albumID + 1).toString().padStart(2, "0")}`}
            onClick={(e) => {
              e.preventDefault();
              viewAlbum();
              window.history.pushState(
                "",
                "",
                `/media?id=a${(albumID + 1).toString().padStart(2, "0")}`
              );
            }}
          >
            {album}
          </a>
        </button>
        <div
          className="vertical-divider"
          style={{ margin: "0 14px 0 20px" }}
        ></div>
        <div className="volume-outer">
          <div
            className="volume-container"
            data-volume={Math.floor(volume)}
            data-volume-state="high"
            style={{ "--width": `${volume}%` }}
          >
            <button id="volume-icon" className="control-button" title="Mute">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="#dddddd">
                <g className="mute low high">
                  <path d="M0 12V6H4L5.70219 4.29781L9 7.57812V17L4 12H0Z" />
                  <path d="M12.8074 11.3962C12.3673 12.0904 11.7414 12.6543 11 13.02V9.58877L12.8074 11.3962Z" />
                  <path d="M13.5 9C13.5 7.23 12.48 5.71 11 4.97V6.76L13.48 9.24C13.49 9.16 13.5 9.08 13.5 9Z" />
                  <path d="M9.00005 1L7.12005 2.88L9.00005 4.76V1Z" />
                </g>
                <g className="low high">
                  <path d="M7.12002 2.88L9.00002 4.76V7.58542L5.5 4.5L7.12002 2.88Z" />
                  <path d="M11 6.76L13.48 9.24C13.4329 10.0684 13.2932 10.5391 12.808 11.3912L11 9.62109V6.76Z" />
                </g>
                <g className="mute high">
                  <path d="M11 15.71V17.77C13.0205 17.3115 14.7821 16.1751 16.0326 14.6128L14.6102 13.1861C13.7198 14.3774 12.4594 15.2757 11 15.71Z" />
                  <path d="M16 9C16 9.82 15.85 10.61 15.59 11.34L17.12 12.87C17.68 11.7 18 10.39 18 9C18 4.72 15.01 1.14 11 0.23V2.29C13.89 3.15 16 5.83 16 9Z" />
                </g>
                <path
                  className="high"
                  d="M17.12 12.87L15.59 11.34C15.295 12.1021 15.0621 12.5048 14.6101 13.1862L16.0326 14.6128C16.5571 13.8918 16.8077 13.5196 17.12 12.87Z"
                />
                <path
                  className="mute"
                  d="M1.5 0L0 1.5L4.29005 5.7L9.00005 10.41L13.1801 14.59L14.6101 16.01L16.5 18L18 16.5L1.5 0Z"
                />
              </svg>
            </button>
            <div
              className="volume-bar-outer"
              data-volume={Math.floor(volume)}
              onMouseMove={(e) => {
                const percent =
                  ((e.clientX - e.target.getBoundingClientRect().left) /
                    e.target.getBoundingClientRect().width) *
                  100;
                e.target.setAttribute(
                  "style",
                  `--hover-width:${percent}%;--colour:${
                    mouseDown
                      ? "#437cb2"
                      : percent < volume
                      ? "#2f577c"
                      : "#a6a6a6"
                  };--z-index:${percent < volume ? "3" : "1"}`
                );
              }}
            >
              <div className="volume-bar"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="menu" data-open={menuOpen}>
        <a
          href={`/media?id=a${(albumID + 1).toString().padStart(2, "0")}`}
          onClick={(e) => e.preventDefault()}
          style={{ display: "contents" }}
        >
          <button
            className="option"
            aria-label="DETAILS"
            onMouseDown={() => {
              viewAlbum();
              window.history.pushState(
                "",
                "",
                `/media?id=a${(albumID + 1).toString().padStart(2, "0")}`
              );
            }}
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="#dddddd">
              <path d="M7.2 4H8.8V5.6H7.2V4ZM7.2 7.2H8.8V12H7.2V7.2ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z" />
            </svg>
          </button>
        </a>
        <button className="option" aria-label="FEATURED">
          <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
            <path d="M14.5455 0H1.45455C0.654545 0 0 0.654545 0 1.45455V10.1818C0 10.9818 0.654545 11.6364 1.45455 11.6364H6.54545V13.0909H5.09091V14.5455H10.9091V13.0909H9.45455V11.6364H14.5455C15.3455 11.6364 16 10.9818 16 10.1818V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 10.1818H1.45455V1.45455H14.5455V10.1818Z" />
          </svg>
        </button>
        <button className="option" aria-label="PURCHASE">
          <svg width="10" height="16" viewBox="0 0 10 16" fill="#dddddd">
            <path d="M4.8711 7.02222C2.85333 6.49778 2.20444 5.95556 2.20444 5.11111C2.20444 4.14222 3.10222 3.46667 4.60444 3.46667C6.18666 3.46667 6.77332 4.22222 6.82666 5.33333H8.7911C8.72888 3.80444 7.3511 2.23111 5.49332 1.77778V0H3.71555V1.77778C1.9911 2.15111 0.16 3.41333 0.16 5.12889C0.16 7.18222 1.85778 8.20444 4.33777 8.8C6.55999 9.33333 7.00444 10.1156 7.00444 10.9422C7.00444 11.5556 6.56888 12.5333 4.60444 12.5333C2.77333 12.5333 2.05333 11.7156 1.95556 10.6667H0C0.106667 12.6133 2.00888 13.8578 3.71555 14.2222V16H5.49332V14.2222C7.22666 13.8933 9.04888 12.7556 9.04888 10.9333C9.04888 8.40889 6.88888 7.54667 4.8711 7.02222Z" />
          </svg>
        </button>
        <button className="option" aria-label="SHARE">
          <svg width="13" height="14" viewBox="0 0 13 14" fill="#dddddd">
            <path d="M10.5422 9.89558C10.008 9.89558 9.53012 10.1064 9.16466 10.4367L4.15361 7.52008C4.18875 7.35843 4.21687 7.19679 4.21687 7.02811C4.21687 6.85944 4.18875 6.69779 4.15361 6.53614L9.10843 3.64759C9.48795 3.999 9.98695 4.21687 10.5422 4.21687C11.7088 4.21687 12.6506 3.2751 12.6506 2.10843C12.6506 0.941767 11.7088 0 10.5422 0C9.3755 0 8.43373 0.941767 8.43373 2.10843C8.43373 2.27711 8.46185 2.43875 8.49699 2.6004L3.54217 5.48896C3.16265 5.13755 2.66365 4.91968 2.10843 4.91968C0.941767 4.91968 0 5.86145 0 7.02811C0 8.19478 0.941767 9.13655 2.10843 9.13655C2.66365 9.13655 3.16265 8.91867 3.54217 8.56727L8.54618 11.491C8.51104 11.6386 8.48996 11.7932 8.48996 11.9478C8.48996 13.0793 9.41064 14 10.5422 14C11.6737 14 12.5944 13.0793 12.5944 11.9478C12.5944 10.8163 11.6737 9.89558 10.5422 9.89558Z" />
          </svg>
        </button>
        <div
          style={{
            width: "50px",
            height: "1px",
            backgroundColor: "#505050",
            margin: "10px -10px 20px",
          }}
        ></div>
        <button
          className="option"
          aria-label="LIKE"
          data-liked={userRating === "POSITIVE"}
          onMouseDown={(e) => {
            e.stopPropagation();
            thumbsUp();
          }}
          data-likes={positiveRating}
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
            <path d="M9.57818 0L4.36364 5.22182V14.5455H13.3164L16 8.29091V5.09091H9.95636L10.7709 1.17818L9.57818 0ZM0 5.81818H2.90909V14.5455H0V5.81818Z" />
          </svg>
        </button>
        <button
          className="option"
          aria-label="DISLIKE"
          data-disliked={userRating === "NEGATIVE"}
          onMouseDown={(e) => {
            e.stopPropagation();
            thumbsDown();
          }}
          data-dislikes={negativeRating}
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
            <path d="M6.42182 14.5455L11.6364 9.32364V0H2.68364L0 6.25455V9.45455H6.04364L5.22909 13.3673L6.42182 14.5455ZM13.0909 0H16V8.72727H13.0909V0Z" />
          </svg>
        </button>
      </div>
      <div className="center">
        <button
          id="featured-video"
          className="control-button"
          title="Featured Video"
          onClick={featuredVideo}
        >
          <svg width="21" height="19" viewBox="0 0 21 19" fill="#dddddd">
            <path d="M19.0909 0H1.90909C0.859091 0 0 0.855 0 1.9V13.3C0 14.345 0.859091 15.2 1.90909 15.2H8.59091V17.1H6.68182V19H14.3182V17.1H12.4091V15.2H19.0909C20.1409 15.2 21 14.345 21 13.3V1.9C21 0.855 20.1409 0 19.0909 0ZM19.0909 13.3H1.90909V1.9H19.0909V13.3Z" />
          </svg>
        </button>
        <div className="vertical-divider"></div>
        <button
          id="shuffle"
          className="control-button"
          title="Shuffle"
          onClick={shuffleList}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill={shuffle ? "#dddddd" : "#808080"}
          >
            <path d="M6.59 5.17L1.41 0L0 1.41L5.17 6.58L6.59 5.17ZM10.5 0L12.54 2.04L0 14.59L1.41 16L13.96 3.46L16 5.5V0H10.5ZM10.83 9.41L9.42 10.82L12.55 13.95L10.5 16H16V10.5L13.96 12.54L10.83 9.41Z" />
          </svg>
        </button>
        <button
          id="previous"
          className="control-button"
          style={{ margin: "0 18px" }}
          title="Previous"
          onClick={index !== 0 ? previousTrack : null}
          data-disabled={index === 0}
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="#dddddd">
            <path d="M0 0H2V12H0V0ZM3.5 6L12 12V0L3.5 6Z" />
          </svg>
        </button>
        <button
          id="play-pause"
          className="control-button"
          data-playing={playing}
          title="Play"
          onClick={play_pause}
        >
          <svg width="22" height="28" viewBox="0 0 22 28" fill="#dddddd">
            <path />
            <path />
          </svg>
        </button>
        <button
          id="next"
          className="control-button"
          style={{ margin: "0 18px" }}
          title="Next"
          onClick={index !== list.length - 1 ? nextTrack : null}
          data-disabled={index === list.length - 1}
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="#dddddd">
            <path d="M0 12L8.5 6L0 0V12ZM10 0V12H12V0H10Z" />
          </svg>
        </button>
        <button
          id="loop"
          className="control-button"
          title="Loop"
          onClick={loopTrack}
        >
          <svg width="16" height="22" viewBox="0 0 16 22">
            {loop === 1 ? (
              <>
                <path
                  d="M7.98857 17C6.39727 17 4.87114 16.3679 3.74593 15.2426C2.62071 14.1174 1.98857 12.5913 1.98857 11C1.98524 10.023 2.22584 9.06053 2.68857 8.2L1.22857 6.74C0.467583 7.94961 0.0445076 9.34098 0.0033235 10.7695C-0.0378606 12.1979 0.304351 13.6114 0.994379 14.8628C1.68441 16.1143 2.69706 17.158 3.92706 17.8856C5.15706 18.6131 6.55949 18.998 7.98857 19V22L11.9886 18L7.98857 14V17Z"
                  fill="#DDDDDD"
                />
                <path
                  d="M7.98857 3V0L3.98857 4L7.98857 8V5C9.57986 5 11.106 5.63214 12.2312 6.75736C13.3564 7.88258 13.9886 9.4087 13.9886 11C13.9919 11.977 13.7513 12.9395 13.2886 13.8L14.7486 15.26C15.5095 14.0504 15.9326 12.659 15.9738 11.2305C16.015 9.80206 15.6728 8.38863 14.9828 7.13719C14.2927 5.88574 13.2801 4.84198 12.0501 4.11442C10.8201 3.38685 9.41764 3.00204 7.98857 3Z"
                  fill="#437CB2"
                />
              </>
            ) : loop === 2 ? (
              <path
                d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                fill="#437CB2"
              />
            ) : (
              <path
                d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                fill="#808080"
              />
            )}
          </svg>
        </button>
        <div className="vertical-divider"></div>
        <button
          id="youtube"
          className="control-button"
          title="YouTube"
          onClick={youtube}
        >
          <svg width="26" height="18" viewBox="0 0 26 18" fill="#dddddd">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.1193 2.81917C24.9716 2.27452 24.6839 1.778 24.2849 1.37895C23.8858 0.979905 23.3893 0.692225 22.8447 0.544494C20.8505 1.17742e-07 12.8239 0 12.8239 0C12.8239 0 4.79692 0.01605 2.80232 0.560544C2.25766 0.708275 1.76114 0.995955 1.3621 1.395C0.963053 1.79405 0.675372 2.29056 0.527642 2.83522C0 4.82983 0 9 0 9C0 9 0 13.1702 0.544093 15.1808C0.691823 15.7255 0.979504 16.222 1.37855 16.621C1.77759 17.0201 2.27411 17.3078 2.81877 17.4555C4.81297 18 12.8399 18 12.8399 18C12.8399 18 20.8669 18 22.8615 17.4559C23.4062 17.3082 23.9027 17.0205 24.3017 16.6214C24.7008 16.2224 24.9885 15.7259 25.1362 15.1812C25.6799 13.1866 25.6799 9 25.6799 9C25.6799 9 25.6638 4.82983 25.1193 2.81917ZM16.9275 8.99999L10.2688 12.8568L10.2684 5.14319L16.9275 8.99999Z"
            />
          </svg>
        </button>
      </div>
      <div className="right">
        <button
          className="control-button"
          id="autoplay"
          title="Auto Play"
          data-autoplay={autoplay}
          onClick={autoPlay}
        >
          <span className="autoplay-icon"></span>
        </button>
        <button id="share-icon" className="control-button" title="Share">
          <svg width="15" height="16" viewBox="0 0 15 16" fill="#dddddd">
            <path d="M12.0482 11.3092C11.4378 11.3092 10.8916 11.5502 10.4739 11.9277L4.74699 8.59438C4.78715 8.40964 4.81928 8.2249 4.81928 8.03213C4.81928 7.83936 4.78715 7.65462 4.74699 7.46988L10.4096 4.16867C10.8434 4.57028 11.4137 4.81928 12.0482 4.81928C13.3815 4.81928 14.4578 3.74297 14.4578 2.40964C14.4578 1.07631 13.3815 0 12.0482 0C10.7149 0 9.63855 1.07631 9.63855 2.40964C9.63855 2.60241 9.67068 2.78715 9.71084 2.97189L4.04819 6.27309C3.61446 5.87149 3.04418 5.62249 2.40964 5.62249C1.07631 5.62249 0 6.69879 0 8.03213C0 9.36546 1.07631 10.4418 2.40964 10.4418C3.04418 10.4418 3.61446 10.1928 4.04819 9.79116L9.76707 13.1325C9.72691 13.3012 9.70281 13.4779 9.70281 13.6546C9.70281 14.9478 10.755 16 12.0482 16C13.3414 16 14.3936 14.9478 14.3936 13.6546C14.3936 12.3614 13.3414 11.3092 12.0482 11.3092Z" />
          </svg>
        </button>
        <button
          id="track-menu"
          className="control-button"
          title="Options"
          onClick={options}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <svg width="4" height="16" viewBox="0 0 4 16" fill="#dddddd">
            <path d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z" />
          </svg>
        </button>
      </div>
      <div className="options" data-open={optionsOpen}>
        <a
          href={`/media?id=a${(albumID + 1).toString().padStart(2, "0")}`}
          onClick={(e) => e.preventDefault()}
          style={{ display: "contents" }}
        >
          <button
            className="option"
            aria-label="DETAILS"
            onMouseDown={() => {
              viewAlbum();
              window.history.pushState(
                "",
                "",
                `/media?id=a${(albumID + 1).toString().padStart(2, "0")}`
              );
            }}
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="#dddddd">
              <path d="M7.2 4H8.8V5.6H7.2V4ZM7.2 7.2H8.8V12H7.2V7.2ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z" />
            </svg>
          </button>
        </a>
        <button className="option" aria-label="FEATURED">
          <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
            <path d="M14.5455 0H1.45455C0.654545 0 0 0.654545 0 1.45455V10.1818C0 10.9818 0.654545 11.6364 1.45455 11.6364H6.54545V13.0909H5.09091V14.5455H10.9091V13.0909H9.45455V11.6364H14.5455C15.3455 11.6364 16 10.9818 16 10.1818V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 10.1818H1.45455V1.45455H14.5455V10.1818Z" />
          </svg>
        </button>
        <button className="option" aria-label="PURCHASE">
          <svg width="10" height="16" viewBox="0 0 10 16" fill="#dddddd">
            <path d="M4.8711 7.02222C2.85333 6.49778 2.20444 5.95556 2.20444 5.11111C2.20444 4.14222 3.10222 3.46667 4.60444 3.46667C6.18666 3.46667 6.77332 4.22222 6.82666 5.33333H8.7911C8.72888 3.80444 7.3511 2.23111 5.49332 1.77778V0H3.71555V1.77778C1.9911 2.15111 0.16 3.41333 0.16 5.12889C0.16 7.18222 1.85778 8.20444 4.33777 8.8C6.55999 9.33333 7.00444 10.1156 7.00444 10.9422C7.00444 11.5556 6.56888 12.5333 4.60444 12.5333C2.77333 12.5333 2.05333 11.7156 1.95556 10.6667H0C0.106667 12.6133 2.00888 13.8578 3.71555 14.2222V16H5.49332V14.2222C7.22666 13.8933 9.04888 12.7556 9.04888 10.9333C9.04888 8.40889 6.88888 7.54667 4.8711 7.02222Z" />
          </svg>
        </button>
        <button className="option" aria-label="SHARE">
          <svg width="13" height="14" viewBox="0 0 13 14" fill="#dddddd">
            <path d="M10.5422 9.89558C10.008 9.89558 9.53012 10.1064 9.16466 10.4367L4.15361 7.52008C4.18875 7.35843 4.21687 7.19679 4.21687 7.02811C4.21687 6.85944 4.18875 6.69779 4.15361 6.53614L9.10843 3.64759C9.48795 3.999 9.98695 4.21687 10.5422 4.21687C11.7088 4.21687 12.6506 3.2751 12.6506 2.10843C12.6506 0.941767 11.7088 0 10.5422 0C9.3755 0 8.43373 0.941767 8.43373 2.10843C8.43373 2.27711 8.46185 2.43875 8.49699 2.6004L3.54217 5.48896C3.16265 5.13755 2.66365 4.91968 2.10843 4.91968C0.941767 4.91968 0 5.86145 0 7.02811C0 8.19478 0.941767 9.13655 2.10843 9.13655C2.66365 9.13655 3.16265 8.91867 3.54217 8.56727L8.54618 11.491C8.51104 11.6386 8.48996 11.7932 8.48996 11.9478C8.48996 13.0793 9.41064 14 10.5422 14C11.6737 14 12.5944 13.0793 12.5944 11.9478C12.5944 10.8163 11.6737 9.89558 10.5422 9.89558Z" />
          </svg>
        </button>
        <div
          style={{
            width: "50px",
            height: "1px",
            backgroundColor: "#505050",
            margin: "10px -10px 20px",
          }}
        ></div>
        <button
          className="option"
          aria-label="LIKE"
          data-liked={userRating === "POSITIVE"}
          onMouseDown={(e) => {
            e.stopPropagation();
            thumbsUp();
          }}
          data-likes={positiveRating}
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
            <path d="M9.57818 0L4.36364 5.22182V14.5455H13.3164L16 8.29091V5.09091H9.95636L10.7709 1.17818L9.57818 0ZM0 5.81818H2.90909V14.5455H0V5.81818Z" />
          </svg>
        </button>
        <button
          className="option"
          aria-label="DISLIKE"
          data-disliked={userRating === "NEGATIVE"}
          onMouseDown={(e) => {
            e.stopPropagation();
            thumbsDown();
          }}
          data-dislikes={negativeRating}
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
            <path d="M6.42182 14.5455L11.6364 9.32364V0H2.68364L0 6.25455V9.45455H6.04364L5.22909 13.3673L6.42182 14.5455ZM13.0909 0H16V8.72727H13.0909V0Z" />
          </svg>
        </button>
      </div>
      <div
        className="progress-bar"
        data-can-open={!optionsOpen && !menuOpen}
        data-time={toMinutes(Math.round(currentTime))}
        data-duration={toMinutes(duration)}
        style={{
          "--progress": `${(currentTime / duration) * 100}%`,
          "--progress-colour":
            seekPosition === "SEEKING" ? "transparent" : "#437cb2",
          "--hover-position": `${hoverPosition * 100}%`,
          "--hover-colour":
            seekPosition === "BACKWARD"
              ? "#2f577c"
              : seekPosition === "FORWARD"
              ? "#a6a6a6"
              : "#437cb2",
          "--hover-z-index": seekPosition === "BACKWARD" ? "0" : "-1",
        }}
      >
        <div id="progress"></div>
        <div className="waveformContainer"></div>
        <div className="waveformContainer"></div>
        <div
          id="hover-time"
          data-time={toMinutes(duration * hoverPosition)}
        ></div>
      </div>
    </div>
  );
}
